import "./src/styles/global.css"
import { AuthContextProvider } from "./src/routes/context"
import React from "react"
import { ModalContextProvider } from "./src/routes/ModalContext"

export const wrapRootElement = ({ element }) => (
  <AuthContextProvider>
      <ModalContextProvider>{element}</ModalContextProvider>
  </AuthContextProvider>
)
