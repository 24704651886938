// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-achievements-js": () => import("./../../../src/pages/achievements.js" /* webpackChunkName: "component---src-pages-achievements-js" */),
  "component---src-pages-app-js": () => import("./../../../src/pages/app/[...].js" /* webpackChunkName: "component---src-pages-app-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-developer-js": () => import("./../../../src/pages/developer.js" /* webpackChunkName: "component---src-pages-developer-js" */),
  "component---src-pages-glimpses-js": () => import("./../../../src/pages/glimpses.js" /* webpackChunkName: "component---src-pages-glimpses-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-join-js": () => import("./../../../src/pages/join.js" /* webpackChunkName: "component---src-pages-join-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-ourteam-js": () => import("./../../../src/pages/ourteam.js" /* webpackChunkName: "component---src-pages-ourteam-js" */),
  "component---src-pages-pasckathon-js": () => import("./../../../src/pages/pasckathon.js" /* webpackChunkName: "component---src-pages-pasckathon-js" */),
  "component---src-pages-pulzion-js": () => import("./../../../src/pages/pulzion.js" /* webpackChunkName: "component---src-pages-pulzion-js" */),
  "component---src-pages-whatwedo-js": () => import("./../../../src/pages/whatwedo.js" /* webpackChunkName: "component---src-pages-whatwedo-js" */)
}

